<template>
  <button
    :class="
      twMerge(
        'group/wallet-item neurial-grotesk box-border flex h-14 min-w-0 cursor-pointer items-center gap-2 !rounded-2xl border border-solid border-white/10 !px-4 py-[13px] transition-all duration-200 md:min-w-40 md:max-w-40',
        props.selected
          ? '!rounded-r-[40px] border-[#ECBA33]/20 bg-[#ECBA33]/20 !py-1.5 !pr-2'
          : props.disabled
            ? 'pointer-events-none border-none bg-black/40 opacity-50'
            : 'hover:!rounded-r-[28px] hover:bg-white/5 hover:!py-1.5 hover:!pr-2',
        props.className,
      )
    "
  >
    <div
      v-if="props.selected"
      class="aspect-square w-2.5 rounded-full border border-solid border-white/10 bg-[#ECBA33] bg-clip-content p-px"
    />
    <span
      class="flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-start text-sm leading-none"
      >{{ props.name }}</span
    >
    <div
      class="aspect-square h-full rounded-full bg-white/5 p-1 transition-all duration-300 group-hover/wallet-item:!p-2"
      :class="props.selected ? 'bg-[#ECBA33]/5 !p-2' : ''"
    >
      <img
        loading="lazy"
        :src="props.logoUri"
        alt=""
        class="h-full w-full object-contain"
        @error="onImageError"
      />
    </div>
  </button>
</template>

<script setup lang="ts">
import { twMerge } from 'tailwind-merge'

interface IProps {
  name: string
  logoUri: string
  disabled: boolean
  selected?: boolean
  className?: string
}

const props = defineProps<IProps>()
const onImageError = (e: Event) => {
  if (!(e.target instanceof HTMLImageElement)) {
    return
  }
  e.target.src = '/tokens/unknown.png'
}
</script>
