<template>
  <div class="grid gap-2.5 p-5 md:grid-cols-2">
    <div class="flex w-full items-center justify-between md:col-span-2">
      <div class="larken text-base md:col-span-2">Choose Wallet</div>
      <!-- <span class="text-xs text-white/50">{{ selectedCount }}/3</span> -->
    </div>
    <div class="text-xs text-white/50 md:col-span-2">Software Wallets</div>
    <WalletItem
      v-for="(wallet, index) in softwareWallets"
      :key="index"
      :disabled="wallet.disabled"
      :name="wallet.name"
      :logo-uri="wallet.logo"
      @click="onSelectWallet(wallet)"
    />
    <!-- <div class="md:col-span-2 text-xs text-white/50">Hardware Wallets</div>
      <WalletItem
        v-for="(wallet, index) in hardwareWallets"
        :key="index"
        :disabled="wallet.disabled"
        :name="wallet.name"
        :logo-uri="wallet.logo"
        @click="onSelectWallet(wallet)"
      /> -->
    <div class="text-xs text-white/50 md:col-span-2">Keystore</div>
    <WalletItem
      v-for="(wallet, index) in fileWallets"
      :key="index"
      :disabled="wallet.disabled"
      :name="wallet.name"
      :logo-uri="wallet.logo"
      @click="onSelectWallet(wallet)"
    />
    <div v-if="createNewWallet" class="pt-2.5 md:col-span-2">
      <WalletItem
        :disabled="createNewWallet.disabled"
        :name="createNewWallet.name"
        :logo-uri="createNewWallet.logo"
        class-name="max-w-full w-full"
        @click="onSelectWallet(createNewWallet)"
      />
    </div>
    <!-- <div class="md:col-span-2 pt-2.5">
        <button
          :class="[
            'w-full md:col-span-2 px-5 py-4 rounded-full bg-[#ECBA33] larken text-base text-[#1D2021] leading-none text-center flex items-center justify-center',
            !props.canProceed && ['opacity-60', 'cursor-pointer'],
          ]"
          :disabled="!props.canProceed"
          @click="props.next()"
        >
          Continue
        </button>
      </div> -->
  </div>
</template>

<script setup lang="ts">
import { WalletOption } from '@swapkit/helpers'
import { computed } from 'vue'
import WalletItem from './WalletItem.vue'
import { useSwapkitWalletStore } from '~/store/swapkitWalletStore'
import type { Wallet } from '~/utils/walletconnect'
import { wallets } from '~/utils/walletconnect'

const emits = defineEmits<{ selectWallet: [Wallet] }>()

const { isChainConnected, isWalletTypeConnected } = useSwapkitWalletStore()

const walletToChildWallet =
  (category: Wallet['category']) =>
  <W extends Wallet>(wallet: W): W | W[] => {
    if (wallet.category !== category) {
      return []
    }
    // * create-new is an exception in the sense that it's treated as a separate category
    if (wallet.id === 'create-new') {
      return []
    }

    const walletConnected = isWalletTypeConnected(wallet.type)
    /**
     * clone chains in order to preserve initial state after updating status
     */
    const clonedChains =
      // * select first enabled chain
      WalletOption.METAMASK === wallet.id
        ? wallet.chains.reduce<{ chains: typeof wallet.chains; selectedFirst: boolean }>(
            ({ chains, selectedFirst }, chain) => {
              const chainConnected = isChainConnected(chain.id)
              return {
                chains: chains.concat({
                  ...chain,
                  status: chainConnected ? 'disabled' : selectedFirst ? 'unselected' : 'selected',
                }),
                selectedFirst: selectedFirst || !chainConnected,
              }
            },
            { chains: [], selectedFirst: false },
          ).chains
        : wallet.chains.map((chain) => {
            const chainConnected = isChainConnected(chain.id)
            return {
              ...chain,
              status: chainConnected ? 'disabled' : chain.status,
            }
          })
    return {
      ...wallet,
      disabled: walletConnected,
      chains: clonedChains,
    }
  }
const softwareWallets = computed(() => wallets.flatMap(walletToChildWallet('software')))
const fileWallets = computed(() => wallets.flatMap(walletToChildWallet('file')))
const createNewWallet = computed(() => {
  const wallet = wallets.find((wallet) => {
    return wallet.id === 'create-new'
  })
  return (
    wallet && {
      ...wallet,
      disabled: isWalletTypeConnected(wallet.type),
      chains: wallet.chains.map((chain) => {
        const chainConnected = isChainConnected(chain.id)
        return {
          ...chain,
          status: chainConnected ? 'disabled' : chain.status,
        }
      }),
    }
  )
})

const onSelectWallet = (wallet: Wallet) => {
  emits('selectWallet', wallet)
}
</script>
