<template>
  <div
    v-if="!nextStep"
    class="flex h-[460px] w-full flex-col rounded-2xl bg-[#141414] p-5 md:min-w-[340px] md:max-w-[340px]"
  >
    <div class="flex items-start gap-2 text-base">
      <div class="larken [&_*]:larken flex flex-1 flex-col text-base leading-tight text-white">
        Create New Keystore Wallet
      </div>
      <button
        class="flex cursor-pointer items-center justify-center rounded-lg bg-white/10 p-1.5 text-xs opacity-50 hover:opacity-100"
        @click="onClose"
      >
        <Icon icon="iconamoon:sign-times-duotone" />
      </button>
    </div>
    <div class="flex flex-grow flex-col overflow-auto">
      <div class="mt-5 text-xs text-white/50">Enter a password to protect your new seed phrase</div>
      <div class="mt-5 flex flex-col gap-2.5">
        <label for="create-new-password" class="text-[10px] leading-none text-white/50"
          >Password</label
        >
        <div
          class="flex items-center rounded-2xl border border-solid border-[rgba(255,255,255,0.08)] p-5"
        >
          <input
            id="create-new-password"
            :type="pwdVisible ? 'text' : 'password'"
            :value="password"
            class="w-full text-xs leading-none outline-none"
            placeholder="Password..."
            @input="onPwdChange"
          />
          <button class="flex cursor-pointer" @click="onTogglePwdVisible">
            <Icon :icon="pwdVisible ? 'mage:eye-off' : 'ph:eye'" />
          </button>
        </div>
      </div>
      <div class="mt-auto">
        <button
          class="larken flex w-full cursor-pointer items-center justify-center rounded-full bg-[#ECBA33] px-5 py-4 text-center text-base leading-none text-[#1D2021]"
          :class="password ? '' : 'invisible'"
          @click="onDownload"
        >
          Download
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue/dist/iconify.js'
import { ref } from 'vue'

const emits = defineEmits<{
  download: [password: string]
  close: []
}>()

const password = ref('')
const pwdVisible = ref(false)
const nextStep = ref(false)

const onPwdChange = (e: Event) => {
  if (!(e.target instanceof HTMLInputElement)) {
    return
  }
  password.value = e.target.value
}
const onTogglePwdVisible = () => {
  pwdVisible.value = !pwdVisible.value
}

const onDownload = () => {
  emits('download', password.value)
}
const onClose = () => {
  emits('close')
}
</script>
