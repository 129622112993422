<template>
  <div
    class="flex h-[460px] w-full flex-col rounded-2xl bg-[#141414] p-5 md:min-w-[340px] md:max-w-[340px]"
  >
    <div class="flex items-start gap-2 text-base">
      <div class="larken [&_*]:larken flex flex-1 flex-col text-base leading-tight text-white">
        Seed Phrase
      </div>
      <button
        class="flex cursor-pointer items-center justify-center rounded-lg bg-white/10 p-1.5 text-xs opacity-50 hover:opacity-100"
        @click="onClose"
      >
        <Icon icon="iconamoon:sign-times-duotone" />
      </button>
    </div>
    <div class="flex flex-grow flex-col overflow-auto">
      <div class="mt-5 text-xs text-white/50">
        Write down the following seed phrase on a piece of paper and store it securely. It's your
        backup in case you lose the keystore file.
      </div>
      <div class="mt-5 flex flex-grow flex-col gap-2.5 rounded-2xl bg-[#ECBA33]/5 p-5">
        <span
          role="textbox"
          aria-readonly="true"
          aria-label="Seed Phrase"
          class="text-center text-[10px] text-sm leading-relaxed text-[#ECBA33]"
          >{{ seedPhrase }}</span
        >
        <button
          class="mx-auto mt-auto flex cursor-pointer items-center justify-center gap-1 rounded-full bg-white/5 px-3 py-2 text-center text-[10px] leading-none"
          :class="seedPhraseIsCopied ? 'text-[#3F9]' : ''"
          @click="onCopy"
        >
          Copy to clipboard
          <Icon
            class="text-xs opacity-50"
            :icon="seedPhraseIsCopied ? 'gravity-ui:circle-check' : 'tdesign:file-copy'"
          />
        </button>
      </div>
      <div class="mt-5">
        <button
          class="larken mt-auto flex w-full cursor-pointer items-center justify-center rounded-full bg-[#ECBA33] px-5 py-4 text-center text-base leading-none text-[#1D2021]"
          @click="onStored"
        >
          I've stored my seed phrase
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue/dist/iconify.js'
import { computed, ref } from 'vue'
import { useSnackbarMessage } from '~/store/snackbar'
import { getHumanError } from '~/utils/humanErrors'
import { hasMessage } from '~/utils/main'

interface IProps {
  seedPhrase: string
}
const props = defineProps<IProps>()

const emits = defineEmits<{ stored: []; close: [] }>()

const snackbar = useSnackbarMessage()

const successTimeout = ref<NodeJS.Timeout | null>(null)
const onCopy = async () => {
  const duration = 5000
  if (successTimeout.value) {
    clearTimeout(successTimeout.value)
    snackbar.clear()
  }
  try {
    await navigator.clipboard.writeText(props.seedPhrase)
    snackbar.addSuccess({ title: 'Seed phrase copied to clipboard', duration: 5000 })
    successTimeout.value = setTimeout(() => {
      successTimeout.value = null
    }, duration)
  } catch (error) {
    const title = 'Failed to copy seed phrase to clipboard'
    const errorMessage =
      (typeof error === 'string' && error) || (hasMessage(error) && error.message) || ''
    snackbar.addError({ title, text: getHumanError(errorMessage) })
  }
}
const seedPhraseIsCopied = computed<boolean>(() => !!successTimeout.value)

const onStored = () => {
  emits('stored')
}

const onClose = () => {
  emits('close')
}
</script>
