<template>
  <div
    class="flex h-[460px] w-full flex-col rounded-2xl bg-[#141414] p-5 md:min-w-[340px] md:max-w-[340px]"
  >
    <div class="flex items-start gap-2 text-base">
      <div class="larken [&_*]:larken [&_*]:larken flex flex-1 flex-col text-base leading-tight">
        <span class="text-white">Select Networks for</span>
        <span class="text-[#ECBA33]">{{ props.wallet.name }}</span>
      </div>
      <button
        class="flex cursor-pointer items-center justify-center rounded-lg bg-white/10 p-1.5 text-xs opacity-50 hover:opacity-100"
        @click="onClose"
      >
        <Icon icon="iconamoon:sign-times-duotone" />
      </button>
    </div>
    <div class="flex flex-grow flex-col justify-center overflow-auto">
      <div
        class="mx-auto -mb-40 flex items-center justify-center rounded-full p-8"
        :class="props.wallet.category === 'software' ? 'bg-white' : 'bg-[#ecbb3357]'"
        style="mask-image: linear-gradient(black, transparent 90%)"
      >
        <img
          class="aspect-square w-24 object-contain object-center"
          :src="props.wallet.logo"
          :alt="props.wallet.name"
        />
      </div>
      <div
        class="flex flex-1 overflow-auto pb-4 pt-28"
        style="mask-image: linear-gradient(transparent, black 10px calc(100% - 10px), transparent)"
      >
        <div class="grid w-full items-start gap-2.5 md:grid-cols-2">
          <NetworkItem
            v-if="
              wallet.id !== WalletOption.METAMASK &&
              selectMeta.selectableCount &&
              // * hide toggle all chains option if a single chain is available
              wallet.chains.length > 1
            "
            class="md:col-span-2"
            :selected="selectMeta.allSelected"
            :disabled="isLoadingBalances"
            :title="selectMeta.allSelected ? 'Deselect all' : 'Select all'"
            :chain="selectMeta.allSelected ? 'Deselect all' : 'Select all'"
            is-switch
            @toggle="onToggleAll"
          >
            <template #icon="{ className }">
              <Icon
                :class="className"
                :icon="selectMeta.allSelected ? 'iconamoon:sign-times-duotone' : 'pajamas:check-xs'"
              />
            </template>
          </NetworkItem>
          <NetworkItem
            v-for="({ id, src, title, status }, index) in wallet.chains"
            :key="index"
            :selected="status === 'selected'"
            :disabled="status === 'disabled' || isLoadingBalances"
            :chain="id"
            :title="title"
            :logo-uri="src"
            @toggle="onToggleChain(id)"
          />
        </div>
      </div>
      <div class="larken mt-auto text-base" />
      <div class="col-span-2">
        <button
          class="larken [&_*]:larken flex w-full items-center justify-center rounded-full border border-solid border-white/0 bg-[#ECBA33] px-5 py-3.5 text-center text-base leading-none text-[#1D2021]"
          :class="canConnect ? 'cursor-pointer' : 'border-white/10 bg-white/0 text-white'"
          :aria-disabled="!canConnect"
          :title="canConnect ? undefined : 'Please select at least one network'"
          @click="canConnect ? onConnect() : undefined"
        >
          <span v-if="isLoadingBalances" class="flex items-center gap-1">
            Connecting <Icon icon="line-md:loading-loop" />
          </span>
          <span v-else class="flex items-center gap-2.5">
            {{
              wallet.id === WalletOption.KEYSTORE || wallet.id === 'from-phrase'
                ? 'Continue'
                : 'Connect Wallet'
            }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue/dist/iconify.js'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { WalletOption } from '@swapkit/helpers'
import NetworkItem from './NetworkItem.vue'
import type { WalletChain } from '~/wallets/swapkit'
import { useSwapkitWalletStore } from '~/store/swapkitWalletStore'
import { type Wallet } from '~/utils/walletconnect'

interface IProps {
  wallet: Wallet
}
const props = defineProps<IProps>()

const emits = defineEmits<{
  connect: [Wallet]
  close: []
  toggleChain: [WalletChain]
  selectAll: []
  deselectAll: []
}>()

const swapkitWalletStore = useSwapkitWalletStore()
const { isLoadingBalances } = storeToRefs(swapkitWalletStore)

const onConnect = () => {
  emits('connect', props.wallet)
}
const onClose = () => {
  emits('close')
}
const onToggleChain = (id: WalletChain) => {
  emits('toggleChain', id)
}

const hasSelectedChains = computed<boolean>(() => {
  return props.wallet.chains.some((chain) => chain.status === 'selected')
})
const canConnect = computed<boolean>(() => {
  return hasSelectedChains.value && !isLoadingBalances.value
})
const selectMeta = computed(() => {
  return props.wallet.chains.reduce<{ allSelected: boolean; selectableCount: number }>(
    ({ allSelected, selectableCount }, chain) => {
      return {
        // * treat disabled chains as selected
        allSelected: allSelected && (chain.status === 'disabled' || chain.status === 'selected'),
        selectableCount: selectableCount + Number(chain.status !== 'disabled'),
      }
    },
    { allSelected: true, selectableCount: 0 },
  )
})
const onToggleAll = () => {
  if (selectMeta.value.allSelected) {
    emits('deselectAll')
    return
  }
  emits('selectAll')
}
</script>
