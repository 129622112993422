<template>
  <label
    :ref="onFileInputLabel"
    :class="[
      'flex cursor-pointer flex-col items-center justify-center rounded-2xl bg-white/5 p-5',
      { 'flex-1': !props.file },
    ]"
    @drop="onOndrop"
    @dragover="onOndragover"
    @dragleave="onOndragleave"
  >
    <input
      :ref="onFileInput"
      type="file"
      class="hidden"
      :accept="props.accept"
      @change="onFileChange"
    />
    <div class="flex items-center gap-2.5">
      <div class="rounded-full bg-[#ECBA33]/10 p-2.5 text-base text-[#ECBA33]">
        <Icon icon="lucide:file-text" />
      </div>
      <template v-if="file">
        <div class="flex flex-col text-xs">
          <span>{{ file.name }}</span>
          <span class="text-white/50">{{ file.size }} B</span>
        </div>
        <button class="cursor-pointer p-2.5 text-base" @click="onFileReset">
          <Icon icon="ph:trash" />
        </button>
      </template>
    </div>
    <template v-if="!file">
      <span class="mt-5 text-xs">Select Keystore File</span>
      <span class="mt-1.5 text-[10px] text-white/50">or drag & drop your file here</span>
    </template>
  </label>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue/dist/iconify.js'
import { type ComponentPublicInstance, ref, withDefaults } from 'vue'

const props = withDefaults(
  defineProps<{
    file: File | null
    accept?: string
  }>(),
  { accept: '.json' },
)

const emits = defineEmits<{
  change: [e: Event]
  reset: [e: Event]
}>()

const fileInputLabel = ref<HTMLLabelElement | null>(null) // * used for toggling dnd class
const onFileInputLabel = (maybeElement: Element | ComponentPublicInstance | null) => {
  if (!(maybeElement instanceof HTMLLabelElement)) {
    return
  }
  fileInputLabel.value = maybeElement
}
const fileInput = ref<HTMLInputElement>()
const onFileInput = (maybeElement: Element | ComponentPublicInstance | null) => {
  if (!(maybeElement instanceof HTMLInputElement)) {
    return
  }
  fileInput.value = maybeElement
}

const onFileChange = (e: Event) => {
  emits('change', e)
}
const onFileReset = (e: Event) => {
  e.preventDefault()
  emits('reset', e)
  if (fileInput.value) {
    fileInput.value.value = ''
  }
}

const onOndragover = (evt: DragEvent) => {
  fileInputLabel.value?.classList.add('!opacity-50')
  evt.stopPropagation()
  evt.preventDefault()
}
const onOndragleave = (evt: DragEvent) => {
  fileInputLabel.value?.classList.remove('!opacity-50')
  evt.stopPropagation()
  evt.preventDefault()
}
const onOndrop = (evt: DragEvent) => {
  if (!evt.dataTransfer?.files || !fileInput.value) return
  evt.stopPropagation()
  evt.preventDefault()
  const file = evt.dataTransfer.files[0]
  fileInput.value.value = ''
  const dT = new DataTransfer()
  dT.items.add(file)
  fileInput.value.files = dT.files
  const event = new Event('change', {
    bubbles: true,
    cancelable: true,
  })
  fileInput.value.dispatchEvent(event)
}
</script>
