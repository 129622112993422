<template>
  <div
    class="m-auto box-border flex max-h-full w-full max-w-full flex-col gap-4 overflow-auto text-white backdrop-blur-xl md:w-auto md:flex-row"
  >
    <template v-if="props.wallet">
      <GrandWallet
        :wallet="props.wallet"
        @connect="onConnectWallet"
        @close="onReturn"
        @toggle-chain="onToggleChain"
        @select-all="onSelectAll"
        @deselect-all="onDeselectAll"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import GrandWallet from './GrandWallet.vue'
import type { Wallet } from '~/utils/walletconnect'
import type { WalletChain } from '~/wallets/swapkit'

type BgWrapperProps = {
  // open: boolean
  wallet: Wallet | null
}
const props = defineProps<BgWrapperProps>()

const emits = defineEmits<{
  connectWallet: [Wallet]
  return: []
  toggleChain: [WalletChain]
  selectAll: []
  deselectAll: []
}>()
const onConnectWallet = (wallet: Wallet) => {
  emits('connectWallet', wallet)
}
const onReturn = () => {
  emits('return')
}
const onToggleChain = (id: WalletChain) => {
  emits('toggleChain', id)
}
const onSelectAll = () => {
  emits('selectAll')
}
const onDeselectAll = () => {
  emits('deselectAll')
}
</script>
