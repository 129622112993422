<template>
  <a
    :class="[
      'flex cursor-pointer items-center gap-1.5 rounded-[12px] border-x border-y border-solid border-white/10 bg-[#141414] px-3.5 py-2.5',
      {
        'pointer-events-none border-white/5 [&>*]:opacity-50': props.disabled,
        'bg-[#3f351a]': !props.disabled && props.selected,
      },
    ]"
    :aria-disabled="props.disabled"
    :title="props.disabled ? 'Previously connected wallet occupies chain' : undefined"
    role="checkbox"
    :aria-label="props.chain"
    :aria-checked="props.selected"
    tabindex="0"
    @click="props.disabled ? undefined : onToggle()"
  >
    <slot name="icon" :class-name="iconClassName">
      <img v-if="logoUri" :class="iconClassName" :src="logoUri" :alt="props.title" />
    </slot>
    <div class="text-xs leading-none">
      {{ title }}
    </div>
    <label
      v-if="isSwitch"
      class="ml-auto flex cursor-pointer items-center justify-between gap-2 text-sm"
    >
      <input type="checkbox" value="" class="peer sr-only" :checked="selected" />
      <div
        class="peer relative box-border h-3 w-[18px] rounded-full border border-solid border-white/30 bg-[#C99D29]/0 after:absolute after:left-[2px] after:top-[2px] after:aspect-square after:w-1.5 after:rounded-full after:bg-[#C99D29] after:transition-all after:content-[''] peer-checked:bg-[#C99D29] peer-checked:after:translate-x-full peer-checked:after:bg-white rtl:peer-checked:after:-translate-x-full"
      />
    </label>
    <div
      v-else
      class="ml-auto rounded-full bg-[#ECBA33] text-xs text-black"
      :class="!props.disabled && props.selected ? 'visible' : 'invisible'"
    >
      <Icon icon="pajamas:check-xs" />
    </div>
  </a>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue/dist/iconify.js'

interface IProps {
  selected: boolean
  disabled: boolean
  title: string
  chain: string
  logoUri?: string
  isSwitch?: boolean
}
const props = defineProps<IProps>()

const iconClassName = 'w-4 aspect-square rounded-full'

const emits = defineEmits<{ toggle: [] }>()

const onToggle = () => {
  emits('toggle')
}
</script>
