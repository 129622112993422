<template>
  <div class="flex w-full flex-col rounded-2xl bg-[#141414] p-5 md:min-w-[340px] md:max-w-[340px]">
    <div class="flex items-start gap-2 text-base">
      <div class="larken [&_*]:larken flex flex-1 flex-col text-base leading-tight text-white">
        Keystore Wallet
      </div>
      <button
        class="flex cursor-pointer items-center justify-center rounded-lg bg-white/10 p-1.5 text-xs opacity-50 hover:opacity-100"
        @click="onClose"
      >
        <Icon icon="iconamoon:sign-times-duotone" />
      </button>
    </div>
    <div class="flex flex-grow flex-col overflow-auto">
      <div :class="['mt-5 flex flex-col gap-2.5', { 'flex-1': !file }]">
        <span class="text-[10px] leading-none text-white/50">Select File</span>
        <CustomFileInput :file="file" @change="onFileChange" @reset="onFileReset" />
      </div>
      <div class="mt-2.5 flex flex-col gap-2.5">
        <label for="keystore-password" class="text-[10px] leading-none text-white/50"
          >Password</label
        >
        <div class="flex items-center rounded-2xl border border-solid border-white/10 p-5">
          <input
            id="keystore-password"
            :type="visiblePwd ? 'text' : 'password'"
            :value="password"
            class="w-full text-xs leading-none outline-none"
            placeholder="Password..."
            @input="onPwdChange"
          />
          <a class="flex cursor-pointer" @click="onTogglePwdVisible">
            <Icon :icon="visiblePwd ? 'mage:eye-off' : 'ph:eye'" />
          </a>
        </div>
      </div>
      <div class="mt-5 flex grow items-end">
        <button
          :class="[
            'larken col-span-2 flex w-full cursor-pointer items-center justify-center rounded-full bg-[#ECBA33] px-5 py-4 text-center text-base leading-none text-[#1D2021]',
            {
              'opacity-60': isDisabled,
            },
          ]"
          :disabled="isDisabled"
          @click="onConnect"
        >
          {{ isDecrypting ? 'Decrypting...' : 'Connect Wallet' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue/dist/iconify.js'
import { computed, ref } from 'vue'
import { decryptFromKeystore } from '@xchainjs/xchain-crypto'
import { useSnackbarMessage } from '~/store/snackbar'
import { hasMessage } from '~/utils/main'
import { getHumanError } from '~/utils/humanErrors'

const props = defineProps<{ onConnect: (phrase: string) => Promise<unknown> }>()

const emits = defineEmits<{ close: [] }>()

const snackbar = useSnackbarMessage()

const file = ref<File | null>(null) // * fileRef.value.files is ephemeral
const password = ref<string>('')
const visiblePwd = ref(false)
const isDecrypting = ref(false)

const onFileChange = (e: Event) => {
  if (!(e.target instanceof HTMLInputElement)) {
    return
  }
  const firstFile = e.target.files?.item(0)
  if (!firstFile) {
    snackbar.addWarning({ title: 'Cannot select file', text: 'No files found' })
    return
  }
  file.value = firstFile
}
const onFileReset = () => {
  file.value = null
}
const onPwdChange = (e: Event) => {
  if (!(e.target instanceof HTMLInputElement)) {
    return
  }
  password.value = e.target.value
}
const onTogglePwdVisible = () => {
  visiblePwd.value = !visiblePwd.value
}

const onConnect = async () => {
  const errorTitle = 'Failed to connect'
  if (!file.value) {
    snackbar.addWarning({ title: errorTitle, text: 'Please upload a keystore file' })
    return
  }
  const keystoreText = await file.value.text()
  try {
    const keystore = JSON.parse(keystoreText)
    try {
      isDecrypting.value = true
      const phrase = await decryptFromKeystore(keystore, password.value)
      isDecrypting.value = false
      return props.onConnect(phrase)
    } catch (error) {
      const errorMessage =
        (typeof error === 'string' && error) || (hasMessage(error) && error.message) || ''
      snackbar.addError({ title: errorTitle, text: getHumanError(errorMessage) })
    }
  } catch (error) {
    snackbar.addError({ title: errorTitle, text: 'Please select a valid keystore JSON file' })
  }
}

const onClose = () => {
  emits('close')
}

const isDisabled = computed(() => {
  return isDecrypting.value || !file.value
})
</script>
